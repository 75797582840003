<template>
  <div
    class=" font-body overflow-auto fixed z-10 flex justify-center items-center w-full h-full bg-gray-900 bg-opacity-70"
  >
    <!-- Card -->
    <div class="bg-white   w-10/12 md:w-8/12 xl:w-6/12 rounded-lg shadow-lg">
      <!-- Header -->
      <div class="flex justify-end">
        <svg
          @click="closeModal"
          class=" mt-2 mr-2 w-7 text-gray-700 hover:text-red-500 cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>

      <!-- Grid Container -->
      <div class="grid grid-cols-1 md:grid-cols-2">
        <!-- Section Info -->
        <div class="mb-3 col-span-1">
          <div class="md:flex md:mx-4 mx-2">
            <div>
              <div class="flex justify-center"><img class="h-48 w-48" :src="sectionImage" /></div>
              <div class="text-primary-400 text-xl">{{ $store.state.editCartInfo.section }}</div>
              <div class="text-gray-500">{{ $store.state.editCartInfo.name }}</div>
              <div class="text-gray-500">ยาว {{ Number($store.state.editCartInfo.length).toFixed(2) }}m</div>
             
              
           
            </div>
            <div></div>
          </div>
        </div>

        <!-- Buy Spec Section -->
        <div class="col-span-1 mx-4 mt-3">
          <form @submit.prevent="editCart">
            <div class="grid grid-cols-4 mb-2 gap-2 items-center">
              <div class="col-span-1 text-sm text-gray-700">สี</div>
              <div class="col-span-3">
                <input
                  :value="$store.state.editCartInfo.color"
                
                  class="py-1 pl-2 border rounded w-full text-gray-500 focus:outline-none focus:border-primary-500"
                  disabled
                />
               
              
              </div>
            </div>

            <div class="grid grid-cols-4 mb-2 gap-2 items-center">
              <div class="col-span-1 text-sm text-gray-700">ความหนา</div>
              <div class="col-span-3">
                <input
                  disabled
                  :value="$store.state.editCartInfo.thickness"
                  class="py-1 pl-2 border rounded w-full text-gray-500 focus:outline-none focus:border-primary-500 disabled:bg-gray-100"
                />
                
              </div>
            </div>

            <div class="grid grid-cols-4 mb-2 gap-2 items-center">
              <div class="col-span-1 text-sm text-gray-700">น้ำหนัก</div>
              <div class="col-span-3">
                <input
                  disabled
                  :value="$store.state.editCartInfo.weightMin+' - '+$store.state.editCartInfo.weightMax"
                  class="py-1 pl-2 border rounded w-full text-gray-500 focus:outline-none focus:border-primary-500 disabled:bg-gray-100"
                />
                
              </div>
            </div>

            <div class="grid grid-cols-4 mb-2 gap-2 items-center">
              <div class="col-span-1 text-sm text-gray-700">จำนวน</div>
              <div class="col-span-3">
                <input
                  
                  type="number"
                  min="0"
                  v-model="buyQty"
                  class="py-1 pl-3 border rounded w-full text-gray-500 focus:outline-none focus:border-primary-500 disabled:bg-gray-100"
                />
              </div>
            </div>

            <div class="flex justify-end mt-2">
              <button
                class="bg-primary-400 hover:bg-primary-700 focus:outline-none text-white my-2  rounded px-4 py-1"
              >
                Edit
              </button>
            </div>
          </form>

          <div class="md:hidden h-10"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import store from "@/store";
import { backendUrl } from "@/config/constant.ts";
import {  onMounted, ref } from "vue";

export default {
  name: "editCartModal",
  
  setup() {
    const sectionImage = ref(backendUrl + `storage/images/section/` + store.state.editCartInfo.section + `.svg`);
    const buyQty = ref(0);

   onMounted(()=>{
       buyQty.value = store.state.editCartInfo.qty
   })

    const closeModal = () => {
      store.commit("closeEditCartModal");
    };

    
    const editCart = () => {
        store.commit('editCart',{
            index: store.state.editCartIndex,
            cartInfo: {...store.state.editCartInfo , qty:buyQty.value}
        })

        closeModal();
    }
    

    

    return {
      closeModal,
      sectionImage,
      buyQty,
      editCart
     
    };
  },
};
</script>
