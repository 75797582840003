<template>
  <div class="font-body">
    <div class="m-5">
      <router-link
        to="/cart"
        class="print:hidden bg-primary-400 hover:bg-primary-700 focus:outline-none text-white my-2 mr-2 rounded px-4 py-1"
        >Back</router-link
      >
      <button
        @click="cartPrint"
        class=" ml-1 print:hidden bg-blue-400 hover:bg-blue-700 focus:outline-none text-white my-2 mr-2 rounded px-4 py-1"
      >
        Print
      </button>
    </div>

    <div class="mb-4 font-bold text-lg text-center">รายการสินค้าที่ต้องการ</div>
    <div class="mb-2 pl-2 text-xs font-light">ชื่อลูกค้า : บริษัท เทสล่า จำกัด</div>
    <div class="mb-2 pl-2 text-xs font-light">
      ผู้พิมพ์รายการ : นายอีลอน มัสก์ (Technoking แห่งเทสล่า)
    </div>
    <div class="mb-4 pl-2 text-xs font-light">วันที่ : 17/03/2021</div>

    <table class="table-auto w-full">
      <thead>
        <tr class="border-b border-gray-300 ">
          <th class="text-2xs text-left px-2 ">Section</th>
          <th class="text-2xs text-left px-2 ">Name</th>
          <th class="text-2xs text-right px-2">Length</th>
          <th class="text-2xs text-left px-2">Color</th>
          <th class="text-2xs text-left px-2">Thickness</th>
          <th class="text-2xs text-right px-2">Min-Max</th>
          <th class="text-2xs text-right px-2">Qty</th>
          <th class="text-2xs text-right px-2">Weight(kg)</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(cart, index) in $store.state.carts"
          class="border-b border-gray-100"
          :key="index"
        >
          <td class="text-left px-2 py-2 text-2xs text-primary-400 whitespace-nowrap">{{ cart.section }}</td>
          <td class="text-left px-2 py-2 text-2xs font-light ">{{ cart.name }}</td>
          <td class="text-right px-2 py-2 text-2xs font-light">{{ cart.length }}</td>
          <td class="text-left px-2 py-2 text-2xs font-light">{{ cart.color }}</td>
          <td class="text-left px-2 py-2 text-2xs font-light">{{ cart.thickness }}</td>
          <td class="text-right px-2 py-2 text-2xs font-light whitespace-nowrap">{{ Number(cart.weightMin).toFixed(2)+'-'+ Number(cart.weightMax).toFixed(2) }}</td>
          <td class="text-right px-2 py-2 text-2xs font-light">{{ cart.qty }}</td>
          <td class="text-right px-2 py-2 text-2xs font-light">
            {{ numberWithCommas(Number(cart.qty * cart.weightMax).toFixed(2)) }}
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="text-right px-2 py-2 text-2xs font-bold  ">Total</td>
          <td class="text-right px-2 py-2 text-2xs font-bold totalText">{{ totalWeight }}</td>
        </tr>
      </tbody>
    </table>

    
  </div>
</template>

<script>
import { numberWithCommas } from "@/common/common.ts";
import { computed } from "vue";
import store from "@/store";

export default {
  setup() {
    const cartPrint = () => {
      window.print();
    };

    const totalWeight = computed(() => {
      let weight = 0;

      store.state.carts.forEach((v) => {
        weight = weight + v.qty * v.weightMax;
      });

      return numberWithCommas(Number(weight).toFixed(2));
    });

    return {
      cartPrint,
      numberWithCommas,
      totalWeight,
    };
  },
};
</script>

<style>

/* @page { size: landscape; } */

.totalText {
  text-decoration: underline double black;
}


</style>
