import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Layout from '@/secure/Layout.vue';
import Login from '@/public/Login.vue';
import Catalog from '@/secure/catalog/Catalog.vue'
import Cart from '@/secure/cart/Cart.vue'
import CartPrint from '@/secure/cart/CartPrint.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    component: Login
  },
  {
    path: '/cartprint',
    component: CartPrint
  },
  {
    path: '',
    component: Layout,
    children: [
      {path:'' , redirect: '/catalog'},
      {path:'/catalog' , component: Catalog},
      {path:'/cart' , component: Cart},
   
    ]
  }
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
