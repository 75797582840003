import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './index.css'
import axios from 'axios'
import {apiUrl} from '@/config/constant.ts'


//axios.defaults.baseURL ="http://localhost:8000/api/";
axios.defaults.baseURL = apiUrl;
axios.defaults.headers['Accept'] = 'application/json'
axios.defaults.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`

createApp(App).use(store).use(router).mount('#app')
