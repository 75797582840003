import { createStore } from 'vuex'
import axios from 'axios'
import ICart from '@/models/cart'
import ICatalog from '@/models/catalog'

export default createStore({
  state: {
    catalogs : null as ICatalog[] | null ,
    buyModalShow : false ,
    buySection : "",
    loading : true ,
    carts: [] as ICart[],
    editCartShow : false ,
    editCartIndex : null as number | null,
    editCartInfo : null as ICart | null
  },
  mutations: {
    setLoading(state,payload){
      state.loading = payload
    },
    loadCatalog(state,payload){

      const data: any = []

      payload.map((v: any)=>{
        data.push(v)
      })

      state.catalogs = data;
    },
    openBuyModal(state){
      state.buyModalShow = true;
    },
    closeBuyModal(state){
      state.buyModalShow = false
    },
    setBuySection(state,section){
      state.buySection = section
    } ,
    addToCart(state,payload){

      //check avilable
      const avialableItems = state.carts.filter((v: ICart)=>{
        return v.section === payload.section && v.length===payload.length && v.color===payload.color && v.weightMin===payload.weightMin && v.weightMax===payload.weightMax
      })

      if(avialableItems.length===0){
        state.carts.push(payload)
      }else{

        state.carts.forEach((v: ICart) => {
          

          if(v.section === payload.section && v.length===payload.length && v.color===payload.color && v.weightMin===payload.weightMin && v.weightMax===payload.weightMax){
            v.qty = v.qty + payload.qty
          }
          
        });

      }

    },
    removeCart(state,index){
      state.carts = state.carts.filter((v: ICart,i: number)=>{
        return index!==i
      })
    },
    openEditCartModal(state){
      state.editCartShow = true
    },
    closeEditCartModal(state){
      state.editCartShow = false
    },
    setEditCartIndex(state,payload){
      state.editCartIndex = payload.index
      state.editCartInfo = payload.cartInfo
    },
    editCart(state,payload){
      state.carts[payload.index] = payload.cartInfo
    }
  },
  actions: {
    async loadCatalog(context){
      

      try {
        context.commit('setLoading',true)

        const response = await axios.get("cataloglistbysection");

        context.commit('loadCatalog',response.data)
        context.commit('setLoading',false)

      } catch (e) {
        context.commit('setLoading',false)
        console.log(e);
      }

    }
  },
  modules: {
  }
})
