
import { onMounted, ref } from "vue";
import store from "@/store";
import { backendUrl } from "@/config/constant";
import ICatalog from '@/models/catalog'

export default {
  setup() {
    const filterCatalogs = ref([] as ICatalog[]);
    const filterText = ref("");
    const baseImageUrl = ref(backendUrl);

    onMounted(async () => {
      if (store.state.catalogs === null) {
        await store.dispatch("loadCatalog");

        store.state.catalogs!.map((v: ICatalog) => {
          filterCatalogs.value.push(v);
        });
      } else {
        store.state.catalogs.map((v) => {
          filterCatalogs.value.push(v);
        });
      }
    });

    const filterCatalog = () => {
      filterCatalogs.value = [];

      if (filterText.value === "") {
        filterCatalogs.value = store.state.catalogs!.filter(() => {
          return true;
        });
      } else {
        filterCatalogs.value = store.state.catalogs!.filter((v) => {
          return (
            v.section.toUpperCase().indexOf(filterText.value.toUpperCase()) >= 0 ||
            v.name.toUpperCase().indexOf(filterText.value.toUpperCase()) >= 0 ||
            v.type.toUpperCase().indexOf(filterText.value.toUpperCase()) >= 0 ||
            v.series.toUpperCase().indexOf(filterText.value.toUpperCase()) >= 0 ||
            v.system.toUpperCase().indexOf(filterText.value.toUpperCase()) >= 0 ||
            v.group.toUpperCase().indexOf(filterText.value.toUpperCase()) >= 0
          );
        });
      }
    };

    const buyClick = (section: string, length: number) => {
      store.commit("setBuySection", section);
      store.commit("openBuyModal");
    };

    return {
      filterCatalogs,
      filterText,
      filterCatalog,
      baseImageUrl,
      buyClick,
    };
  },
};
