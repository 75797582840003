<template>
  <div class="mt-2 mx-2 md:mx-8 flex flex-col">
    <h2 class="text-lg md:text-2xl font-bold uppercase">Cart</h2>
    <div class="mt-4">
      <router-link
        to="/cartprint"
        class=" bg-primary-400 hover:bg-primary-700 focus:outline-none text-white my-2 mr-2 rounded px-4 py-1"
        >Print</router-link
      >
      <span class="ml-2">น้ำหนักรวม {{totalWeight}} kg</span>
    </div>
    <div class="mt-4 bg-white shadow rounded-md">
      <div class="px-2 py-4 overflow-hidden">
        <table class="table-auto w-full">
          <thead>
            <tr class="border-b border-gray-300 ">
              <th class="text-left px-2 text-2xs md:text-sm">Section</th>
              <th class="text-left px-2 text-2xs md:text-sm hidden lg:table-cell">Name</th>
              <th class="text-right px-2 text-2xs md:text-sm">L</th>
              <th class="text-left px-2 text-2xs md:text-sm">Color</th>
              <th class="text-left px-2 text-2xs md:text-sm">Thickness</th>
              <th class="text-right px-2 text-2xs md:text-sm whitespace-nowrap">Min-Max</th>
              <th class="text-right px-2 text-2xs md:text-sm">Qty</th>
              <th class="text-right px-2 text-2xs md:text-sm hidden lg:table-cell">Weight</th>
              <th class="text-center px-2 text-2xs md:text-sm text-gray-400"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(cart, index) in $store.state.carts"
              class="border-b border-gray-100"
              :key="index"
            >
              <td class="text-left px-2 py-2 text-2xs md:text-sm text-primary-400 whitespace-nowrap">{{ cart.section }}</td>
              <td class="text-left px-2 py-2 text-2xs md:text-sm hidden lg:table-cell">{{ cart.name }}</td>
              <td class="text-right px-2 py-2 text-2xs md:text-sm">{{ cart.length }}</td>
              <td class="text-left px-2 py-2 text-2xs md:text-sm">{{ cart.color }}</td>
              <td class="text-left px-2 py-2 text-2xs md:text-sm">{{ cart.thickness }}</td>
              <td class="text-right px-2 py-2 text-2xs md:text-sm whitespace-nowrap">{{ Number(cart.weightMin).toFixed(2)+'-'+Number(cart.weightMax).toFixed(2) }}</td>
              <td class="text-right px-2 py-2 text-2xs md:text-sm">{{ cart.qty }}</td>
              <td class="text-right px-2 py-2 text-2xs md:text-sm hidden lg:table-cell">{{ numberWithCommas( Number(cart.qty * cart.weightMax).toFixed(2) ) }}</td>
              <td class="text-center px-2 py-2 text-2xs md:text-sm whitespace-nowrap ">
                <button
                  @click="
                    editCart(index, {
                      section: cart.section,
                      name: cart.name,
                      length: cart.length,
                      color: cart.color,
                      thickness: cart.thickness,
                      weightMin: cart.weightMin,
                      weightMax: cart.weightMax,
                      qty: cart.qty,
                    })
                  "
                  class="hover:text-blue-400 focus:outline-none"
                >
                  <svg
                    class="w-4 md:w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                    />
                  </svg>
                </button>
                <button
                  class="ml-2 hover:text-primary-400 focus:outline-none"
                  @click="removeCart(index)"
                >
                  <svg
                    class="w-4 md:w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import store from "@/store";
import ICart from "@/models/cart";
import { computed } from 'vue';
import { numberWithCommas } from "@/common/common";
export default {
  setup() {

    const totalWeight = computed(() => {
      let weight = 0;

      store.state.carts.forEach((v) => {
        weight = weight + v.qty * v.weightMax;
      });

      return numberWithCommas(Number(weight).toFixed(2));
    });
    
    const removeCart = (index: number) => {
      if (confirm("Are you sure to delete this item?")) {
        store.commit("removeCart", index);
      }
    };

    const editCart = (index: number, cartInfo: ICart) => {
      store.commit("setEditCartIndex", { index, cartInfo });
      store.commit("openEditCartModal");
    };

    return {
      removeCart,
      editCart,
      totalWeight,
      numberWithCommas
    };
  },
};
</script>
