
import store from "@/store";
import ICart from "@/models/cart";
import { computed } from 'vue';
import { numberWithCommas } from "@/common/common";
export default {
  setup() {

    const totalWeight = computed(() => {
      let weight = 0;

      store.state.carts.forEach((v) => {
        weight = weight + v.qty * v.weightMax;
      });

      return numberWithCommas(Number(weight).toFixed(2));
    });
    
    const removeCart = (index: number) => {
      if (confirm("Are you sure to delete this item?")) {
        store.commit("removeCart", index);
      }
    };

    const editCart = (index: number, cartInfo: ICart) => {
      store.commit("setEditCartIndex", { index, cartInfo });
      store.commit("openEditCartModal");
    };

    return {
      removeCart,
      editCart,
      totalWeight,
      numberWithCommas
    };
  },
};
