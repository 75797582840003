<template>
  <form @submit.prevent="submit">
      <div class="md:flex md:justify-center mt-5">
    <div class="bg-white mx-4 md:w-96 shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col ">
      <div class="mb-4">
        <label class="block text-grey-darker text-sm font-bold mb-2" for="username">
          Email
        </label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
          id="username"
          type="email"
          placeholder="Email"
          v-model="email"
        />
      </div>
      <div class="mb-6">
        <label class="block text-grey-darker text-sm font-bold mb-2" for="password">
          Password
        </label>
        <input
          class="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-darker mb-3"
          id="password"
          type="password"
          placeholder="******************"
          v-model="password"
        />
        <!-- <p class="text-red-500 text-xs italic">Please choose a password.</p> -->
      </div>
      <div class="flex items-center justify-between">
        <button
          class="bg-primary-400 hover:bg-primary-100 text-white font-bold py-2 px-4 rounded"
          type="submit"
        >
          Sign In
        </button>
        
      </div>
    </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import { useRouter } from "vue-router";
import { ref } from "vue";

export default {
  name: "Login",
  setup() {
    const email = ref("");
    const password = ref("");
    const router = useRouter();

    const submit = async () => {
      const response = await axios.post("login", {
        email: email.value,
        password: password.value,
      });

      localStorage.setItem("token", response.data.token);
      axios.defaults.headers["Authorization"] = `Bearer ${response.data.token}`;
      await router.push("/catalog");
    };

    return {
        email,
        password,
        submit
    }
  },
};
</script>

<style></style>
