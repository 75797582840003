<template>
  <li
    class=" items-center  " 
  >
    <router-link :to="link" class="flex py-2 hover:bg-gray-200 text-gray-500" active-class="hover:bg-primary-50 text-primary-400 border-r-4 border-primary-400">
      <slot name="icon"></slot>
      
      <div>{{ title }}<span v-if="number>0" class="ml-2  bg-green-600 text-white text-xs px-2 rounded-l-full rounded-r-full ">{{number}}</span></div>
    </router-link>
  </li>
</template>

<script>
export default {
  props: { link: { default: "" }, title: { default: "" }, number: {default:0} },
  
};
</script>

<style></style>
