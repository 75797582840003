<template>
  <div
    class=" font-body overflow-auto fixed z-10 flex justify-center items-center w-full h-full bg-gray-900 bg-opacity-70"
  >
    <!-- Card -->
    <div class="bg-white   w-10/12 md:w-8/12 xl:w-6/12 rounded-lg shadow-lg">
      <!-- Header -->
      <div class="flex justify-end">
        <svg
          @click="closeModal"
          class=" mt-2 mr-2 w-7 text-gray-700 hover:text-red-500 cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>

      <!-- Grid Container -->
      <div class="grid grid-cols-1 md:grid-cols-2">
        <!-- Section Info -->
        <div class="mb-3 col-span-1">
          <div class="md:flex md:mx-4 mx-2">
            <div>
              <div class="flex justify-center"><img class="h-48 w-48" :src="sectionImage" /></div>
              <div class="text-primary-400 text-xl">{{ section }}</div>
              <div class="text-gray-500">{{ sectionInfo.name }}</div>
              <div class="text-gray-500">ยาว {{ Number(sectionInfo.length).toFixed(2) }}m</div>
              <div class="text-gray-500">{{ sectionInfo.type }} <template v-if="sectionInfo.type !== 'Standard'" >
                {{ sectionInfo.series }}
              </template></div>
              
              <div class="text-gray-300 text-sm">
                {{ sectionInfo.system }} - {{ sectionInfo.group }}
              </div>
            </div>
            <div></div>
          </div>
        </div>

        <!-- Buy Spec Section -->
        <div class="col-span-1 mx-4 mt-3">
          <form @submit.prevent="addToCart">
            <div class="grid grid-cols-4 mb-2 gap-2 items-center">
              <div class="col-span-1 text-sm text-gray-700">สี</div>
              <div class="col-span-3">
                <select
                  v-model="selectColor"
                  @change="pickColor"
                  class="py-1 pl-2 border rounded w-full text-gray-500 focus:outline-none focus:border-primary-500"
                >
                  <option class="text-sm" value="select">--เลือก--</option>
                  <option class="text-sm" value="Tiara8911">Tiara8911</option>
                  <option class="text-sm" value="Tiara8815">Tiara8815</option>
                  <option class="text-sm" value="Tiara8816">Tiara8816</option>
                  <option class="text-sm" value="E&A/M-10">E&A/M-10</option>
                  <option class="text-sm" value="518/M-15">518/M-15</option>
                </select>
              </div>
            </div>

            <div class="grid grid-cols-4 mb-2 gap-2 items-center">
              <div class="col-span-1 text-sm text-gray-700">ความหนา</div>
              <div class="col-span-3">
                <select
                  :disabled="selectColor === 'select'"
                  v-model="selectThickness"
                  class="py-1 pl-2 border rounded w-full text-gray-500 focus:outline-none focus:border-primary-500 disabled:bg-gray-100"
                >
                  <option class="text-sm" value="select">--เลือก--</option>
                  <option class="text-sm" v-for="thickness in thicknesses" :value="thickness.thickness" :key="thickness.thickness">
                      {{thickness.thickness}}  [{{Number(thickness.weight_min).toFixed(2)}}-{{Number(thickness.weight_max).toFixed(2)}}]</option>
                </select>
              </div>
            </div>

            <div class="grid grid-cols-4 mb-2 gap-2 items-center">
              <div class="col-span-1 text-sm text-gray-700">จำนวน</div>
              <div class="col-span-3">
                <input
                  :disabled="selectColor === 'select' || selectThickness === 'select'"
                  type="number"
                  min="0"
                  v-model="buyQty"
                  class="py-1 pl-3 border rounded w-full text-gray-500 focus:outline-none focus:border-primary-500 disabled:bg-gray-100"
                />
              </div>
            </div>

            <div class="flex justify-end mt-2">
              <button
                class="bg-primary-400 hover:bg-primary-700 focus:outline-none text-white my-2  rounded px-4 py-1"
              >
                Buy
              </button>
            </div>
          </form>

          <div class="md:hidden h-10"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import store from "@/store";
import { backendUrl } from "@/config/constant.ts";
import { onMounted, ref } from "vue";
import axios from "axios";
export default {
  name: "buyModal",
  props: {
    section: {
      default: "",
    },
  },
  setup(props) {
    const sectionImage = ref(backendUrl + `storage/images/section/` + props.section + `.svg`);
    const sectionInfo = ref({});
    const masterThicknesses = ref([]);
    const thicknesses = ref([]);
    const selectColor = ref("select");
    const selectThickness = ref("select");
    const buyQty = ref(0);

    if (props.section !== "") {
      sectionInfo.value = store.state.catalogs.filter((v) => {
        return v.section === props.section;
      })[0];
    }

    const closeModal = () => {
      store.commit("closeBuyModal");
    };

    onMounted(async () => {
      const response = await axios(`thicknesslistbysection/${props.section}`);

      response.data.map((v) => {
        masterThicknesses.value.push(v);
      });
    });

    const pickColor = () =>{
        selectThickness.value ="select"

        let colorType = ''

        switch (selectColor.value) {
          case 'Tiara8911':
            colorType = 'PDC'
            break;
          
          case 'Tiara8815':
            colorType = 'PDC'
            break;

          case 'Tiara8816':
            colorType = 'PDC'
            break;

          case 'E&A/M-10':
            colorType = 'ANO'
            break;

          case '518/M-15':
            colorType = 'ANO'
            break;
        
          default:
            break;
        }

        thicknesses.value = masterThicknesses.value.filter((v)=>{
            return v.color_type === colorType
        })
    }

    const addToCart = async () => {

      const response = await axios(`thicknesslistbysection/${props.section}`);

      const thickness = response.data.filter((v)=>{
        return v.thickness === selectThickness.value
      })

      store.commit('addToCart',{
        section: props.section,
        name:sectionInfo.value.name,
        length: Number(sectionInfo.value.length),
        color: selectColor.value,
        thickness: thickness[0].thickness,
        weightMin: Number(thickness[0].weight_min),
        weightMax: Number(thickness[0].weight_max),
        qty: Number(buyQty.value)
      })

      closeModal();
    }

    return {
      closeModal,
      sectionImage,
      sectionInfo,
      thicknesses,
      selectColor,
      selectThickness,
      pickColor,
      buyQty,
      addToCart
    };
  },
};
</script>
