<template>
  <div class="mt-2 mx-2 md:mx-8">
    <h2 class="text-lg md:text-2xl font-bold uppercase">Catalog</h2>

    <!-- Serch Card -->
    <div class="mt-3 px-3  bg-white w-80 rounded-md shadow-md">
      <div class="my-4 py-3  flex items-center">
        <div class="font-bold mr-4">Search</div>
        <input
          type="text"
          v-model="filterText"
          @keyup="filterCatalog"
          class="shadow  border px-2 py-1 border-gray-300 focus:border-primary-500 focus:outline-none rounded w-full text-grey-600"
        />
        <button @click="filterCatalog" class="px-2 hover:text-primary-400 focus:outline-none">
          <svg
            class="w-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </button>
      </div>
    </div>

    <!-- loading -->
    <div v-if="$store.state.loading" class="flex justify-center">
      <img src="img/loading.svg" class="mt-10 w-10 h-10 animate-spin" />
    </div>

    <!-- Grid Container -->
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-4">
      <template v-for="catalog in filterCatalogs" :key="catalog.section">
        <!-- Card -->
        <div class="bg-white rounded-md relative shadow-md hover:shadow-lg">
          <div class="p-10">
            <img
              class="w-full object-contain h-80"
              :src="`${baseImageUrl}storage/images/section/${catalog.section}.svg`"
            />
          </div>
          <div class="absolute top-2 left-2 text-sm text-gray-400">
            <div>
              {{ catalog.type }} {{ catalog.type === "Standard" ? "" : catalog.series }}
              {{ catalog.system }}
            </div>
            <div>{{ catalog.group }}</div>
          </div>

          <div class="mb-4 flex justify-between items-start">
            <div>
              <div class="ml-3 text-xl">{{ catalog.section }}</div>
              <div class="ml-3 text-gray-500">{{ catalog.name }}</div>
              <div class="ml-3 text-gray-500">ยาว {{ Number(catalog.length).toFixed(2) }}m</div>
            </div>
            <button
              @click="buyClick(catalog.section)"
              class="bg-primary-400 hover:bg-primary-700 focus:outline-none text-white my-2 mr-2 rounded px-4 py-1"
            >
              Buy
            </button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { onMounted, ref } from "vue";
import store from "@/store";
import { backendUrl } from "@/config/constant";
import ICatalog from '@/models/catalog'

export default {
  setup() {
    const filterCatalogs = ref([] as ICatalog[]);
    const filterText = ref("");
    const baseImageUrl = ref(backendUrl);

    onMounted(async () => {
      if (store.state.catalogs === null) {
        await store.dispatch("loadCatalog");

        store.state.catalogs!.map((v: ICatalog) => {
          filterCatalogs.value.push(v);
        });
      } else {
        store.state.catalogs.map((v) => {
          filterCatalogs.value.push(v);
        });
      }
    });

    const filterCatalog = () => {
      filterCatalogs.value = [];

      if (filterText.value === "") {
        filterCatalogs.value = store.state.catalogs!.filter(() => {
          return true;
        });
      } else {
        filterCatalogs.value = store.state.catalogs!.filter((v) => {
          return (
            v.section.toUpperCase().indexOf(filterText.value.toUpperCase()) >= 0 ||
            v.name.toUpperCase().indexOf(filterText.value.toUpperCase()) >= 0 ||
            v.type.toUpperCase().indexOf(filterText.value.toUpperCase()) >= 0 ||
            v.series.toUpperCase().indexOf(filterText.value.toUpperCase()) >= 0 ||
            v.system.toUpperCase().indexOf(filterText.value.toUpperCase()) >= 0 ||
            v.group.toUpperCase().indexOf(filterText.value.toUpperCase()) >= 0
          );
        });
      }
    };

    const buyClick = (section: string, length: number) => {
      store.commit("setBuySection", section);
      store.commit("openBuyModal");
    };

    return {
      filterCatalogs,
      filterText,
      filterCatalog,
      baseImageUrl,
      buyClick,
    };
  },
};
</script>
