<template>
  <!-- Modal -->
  <!-- <div v-if="$store.state.buyModalShow" class="overflow-auto fixed z-10 flex justify-center w-screen h-screen bg-gray-900 bg-opacity-50">
    <div class="bg-white mt-5 h-52 w-7/12 rounded-lg shadow-lg">
    modal
    </div>
  </div> -->

  <!--Buy Modal -->
  <transition name="fade">
    <buy-modal v-if="$store.state.buyModalShow" :section="$store.state.buySection"></buy-modal>
  </transition>

  <!-- Edit Cart Modal -->
  <transition name="fade">
    <edit-cart-modal v-if="$store.state.editCartShow" ></edit-cart-modal>
  </transition>
<!-- :style="!menuShow ? `{'background-image': url('img/AIconicHalf.svg')}` : '' "  -->
  
  <div
    class="h-screen md:flex bg-gray-100 text-gray-600 overflow-y-scroll md:overflow-hidden font-body"
  >
    <!-- Fixed sidebar -->
    <div class="bg-white text-gray-600 md:w-64 md:border-r  flex flex-col bg-no-repeat bg-right-bottom  " :style="{'background-image': windowWidth >= 768  ? 'url(' + 'img/AIconicHalf.svg' + ')' : '' }" >
      <!-- Sidebar content -->
      
      <!-- Logo -->
      <div class="flex justify-between items-center md:mt-4">
        <img src="img/alumetcLogo.svg" class="w-44 my-2 md:w-full md:px-4 md:mt-2" />
        <div class="md:hidden mr-3 cursor-pointer" @click="menuToggle">
          <svg
            class="w-7"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </div>
      </div>

      <!-- Menu -->
      <transition name="slide-fade">
        <div v-if="windowWidth < 768 ? menuShow : true" class=" md:block">
          <ul class="mt-0 md:mt-4">
            <menu-item title="Catalog" link="/catalog">
              <template v-slot:icon>
                <svg
                  class="w-5 mx-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                  />
                </svg>
              </template>
            </menu-item>

            <menu-item title="Cart" link="/cart" :number="$store.state.carts.length">
              <template v-slot:icon>
                <svg
                  class="w-5 mx-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
              </template>
            </menu-item>

            <menu-item title="Logout" link="/login" @click="logout">
              <template v-slot:icon>
                <svg
                  class="w-5 mx-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                  />
                </svg>
              </template>
            </menu-item>
          </ul>
        </div>
      </transition>

      <!-- A Iconic -->
      <!-- <div class="mt-auto hidden md:block">
        <img class="opacity-10" src="img/AIconicHalf.svg" />
      </div> -->
    </div>
    <!-- Scroll wrapper -->
    <div class="flex-1 flex  overflow-hidden bg-gray-100">
      <!-- Scrollable container -->
      <div class="flex-1  md:overflow-y-scroll ">
        <!-- Your content -->
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import axios from "axios";
import { useRouter } from "vue-router";
import MenuItem from "@/secure/components/MenuItem.vue";
import BuyModal from "@/secure/components/BuyModal.vue";
import EditCartModal from "@/secure/components/EditCartModal.vue";

export default {
  name: "Layout",
  components: {
    MenuItem,
    BuyModal,
    EditCartModal
  },
  setup() {
    const router = useRouter();
    const user = ref(null);
    const menuShow = ref(false);
    const windowWidth = ref(window.innerWidth);

    onMounted(async () => {
      window.addEventListener("resize", () => {
        windowWidth.value = window.innerWidth;

        if (windowWidth.value < 768){
          menuShow.value = false;
        } else {
          menuShow.value =true;
        }
      });

      //check user
      try {
        const response = await axios.get("user");

        user.value = response.data.data;
      } catch (e) {
        await router.push("/login");
      }
    });

    const logout = () => {
      localStorage.clear();
      router.push("/login");
    };

    const menuToggle = () => {
      menuShow.value = !menuShow.value;
    };

    return { user, logout, menuShow, menuToggle, windowWidth };
  },
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  /* transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1); */
  transition: all 0.2s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-15px);
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}


</style>
